import React from 'react'
import { Link } from 'gatsby'
import { CommittedToClearBadge } from '../Icons'

const CommittedToClearBadgeLink = () => (
  <Link to="/committed-to-clear">
    <CommittedToClearBadge />
  </Link>
)

export default CommittedToClearBadgeLink
