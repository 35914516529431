import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { BottomLineGoogleAdContainer } from '../styles/GoogleAdsCommon.styles'
import AdSlot from '../AdSlot'

const HCP_BOTTOM_LINE_GOOGLE_AD_QUERY = graphql`
  {
    prismicGeneralSiteSettings {
      data {
        hcp_detail_page_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
  }
`

const HcpdDetailPageBottomLineGoogleAd = () => (
  <StaticQuery
    query={`${HCP_BOTTOM_LINE_GOOGLE_AD_QUERY}`}
    render={data => {
      const bottomLineAdSlot =
        data?.prismicGeneralSiteSettings.data?.hcp_detail_page_ad?.document
          ?.data?.ad_slot_name
      if (!bottomLineAdSlot) {
        return null
      }
      return (
        <BottomLineGoogleAdContainer>
          <AdSlot divId={`div-${bottomLineAdSlot}`} />
        </BottomLineGoogleAdContainer>
      )
    }}
  />
)

export default HcpdDetailPageBottomLineGoogleAd
